const Collapse = require("bootstrap/js/dist/collapse");

exports.bootstrapCollapse = () => {
  var collapseElementList = [].slice.call(
    document.querySelectorAll(".collapse")
  );
  var collapseList = collapseElementList.map(function (collapseEl) {
    return new Collapse(collapseEl);
  });
  selectOther = () => {
    var selectElement = document.getElementById("selectother");
    var otherContents = document.getElementById("otherContents");
    var otherForm = document.getElementById("otherForm");
    if (selectElement.checked) {
      otherContents.classList.add("show");
      otherForm.removeAttribute("disabled");
      otherForm.setAttribute("data-formrun-required", true);
      // console.log("選択された値：");
    } else {
      otherContents.classList.remove("show");
      otherForm.setAttribute("disabled", true);
      otherForm.removeAttribute("data-formrun-required");
    }
  }
};

