import "../scss/app.scss";
import Vue from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
const bs = require("./bs");
// console.log(collapse);
bs.bootstrapCollapse();
gsap.registerPlugin(ScrollTrigger);

const trigger = document.querySelectorAll("[data-trigger]");

trigger.forEach(function(userItem) {
  ScrollTrigger.create({
    trigger: userItem,
    start: "top bottom-=50px",
    end: "bottom top+=50px",
    toggleClass: {targets: userItem, className: "InView"}
  });
});


//メニュートグル
const toggleMenu = document.getElementById('toggleMenu');
const togglecont = document.getElementById('togglecont');
toggleMenu.onclick = function(){
  togglecont.classList.toggle('active');
}

const app = new Vue({
  el: "#app",
  data: {
    spnav: false,
    memberModal: true,
  },
});
